/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		//inicio tab-module
		//tabModule.init();
		//inicio sliphover
		$('#container').sliphover({
        	backgroundColorAttr: 'data-background'
        });
		$('#container2').sliphover({
        	backgroundColorAttr: 'data-background'
        });
		//fim sliphover
		
		
		
		//var mtselect = document.getElementsByName('mt-ddw-respostas');
		//mtselect.onchange = function () {
			//mtselect.className = 'mt-ddw-respostas ' + this.options[this.selectedIndex].className;
		//};
		
		//var mtselect = $("[name=mt-ddw-respostas]");
		var mtselect = $('select[name="mt-ddw-respostas[]"]');
		mtselect.each(function(index, value)    
		{
			$(this).change(function(){
				$(this).removeClass();
				//$(this).addClass( 'mt-ddw-respostas ' + $(this).options[$(this).prop('selectedIndex')].attr("class"));
				$(this).addClass( 'mt-ddw-respostas ' + $('option:selected', $(this)).val());
			});
		});
		
				
		/*mtselect.change(function(){
			for(i=1 ; i <= mtselect.length ; i++)
			{
				mtselect[i].removeClass();
				mtselect[i].addClass( 'mt-ddw-respostas ' + this.options[this.selectedIndex].className );
			}
		});*/
		
		
		/** fixa tel tipo **/
		var nav = $('#tel-fixo');   
		$(window).scroll(function () { 
			if ($(this).scrollTop() > 48) { 
				nav.addClass("tel-fixo"); 
			} else { 
				nav.removeClass("tel-fixo"); 
			} 
		});
		// fim do fixa tel
		
		
		/** slick - mugs vs tattoo **/
		$('.autoplay').slick({
			dots: false,
			infinite: true,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
		responsive: [
		{
			breakpoint: 1024,
			settings: 
			{
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: true
			}
		},
		{
			breakpoint: 600,
			settings: 
			{
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 480,
			settings: 
			{
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
		});
		
		
		/** slick - mugs vs tattoo **/
		$('.autoplay2').slick({
			dots: true,
			infinite: true,
			speed: 600,
			slidesToShow: 2,
			slidesToScroll: 2,
			autoplay: true,
			autoplaySpeed: 4000,
		responsive: [
		{
			breakpoint: 1024,
			settings: 
			{
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
				dots: true
			}
		},
		{
			breakpoint: 600,
			settings: 
			{
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 480,
			settings: 
			{
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
		});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
